import store from '@/store'

export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Client',
    children: [
      {
        title: 'Home',
        route: 'home',
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'Cost Calculation',
        route: 'cost',
        action: 'read',
        resource: 'Client',
      },
    ],
  },
  {
    title: 'Account',
    icon: 'UserIcon',
    tagVariant: 'light-warning',
    action: 'read',
    resource: 'Client',
    children: [
      {
        title: 'Personal',
        route: 'ship2u-personal',
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'Password',
        route: 'ship2u-modify-pwd',
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'Shipping Address',
        route: 'ship2u-shipping-address-list',
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'Sender Address',
        route: 'ship2u-sender-address-list',
        action: 'read',
        resource: 'Client',
      },
    ],
  },
  {
    title: 'Parcels',
    icon: 'ArchiveIcon',
    // tag: pWaiting,
    // tagVariant: 'light-warning',
    action: 'read',
    resource: 'Client',
    children: [
      {
        title: 'In Warehouse',
        route: 'waiting_parcels',
        tag: store.getters['ship2u/parcels']({ parcelState: 'P', unsent: 1 }),
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'Processing',
        route: 'processing_parcels',
        tag: store.getters['ship2u/parcels']({ parcelState: 'P', unsent: 0 }),
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'In Transist',
        route: 'out_parcels',
        tag: store.getters['ship2u/parcels']({ parcelState: 'T', unsent: 0 }),
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'History',
        route: 'history_parcels',
        action: 'read',
        resource: 'Client',
      },
    ],
  },
  {
    title: 'Freight Orders',
    icon: 'CheckSquareIcon',
    action: 'read',
    resource: 'Client',
    children: [
      {
        title: 'Create',
        route: 'ship2u-order-add',
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'Processing',
        route: 'processing_order',
        tag: store.getters['ship2u/orders']({ orderState: 'P' }),
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'In Transit',
        route: 'transit_order',
        tag: store.getters['ship2u/orders']({ orderState: 'T' }),
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'Finished Orders',
        route: 'history_order',
        tag: store.getters['ship2u/orders']({ orderState: 'H' }),
        action: 'read',
        resource: 'Client',
      },
      {
        title: 'All Orders',
        route: 'all_order',
        action: 'read',
        resource: 'Client',
      },
    ],
  },
]
