<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <span
        v-if="!$store.state.ship2u.active"
        class="ml-50"
      >
        <b-badge
          id="email_verify"
          variant="light-warning"
        >
          {{ $t('Email Address Not Verified') }}
        </b-badge>
        <b-popover
          target="email_verify"
          triggers="hover focus"
          custom-class="wide-popover"
        >
          <b-card
            border-variant="warning"
            bg-variant="transparent"
            class="shadow-none"
          >
            <b-card-text>
              {{ $t('Email Address Not Verified Hint') }}
            </b-card-text>
          </b-card>
        </b-popover>
      </span>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, VBPopover, BBadge, BCard, BCardText, BPopover,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from './Locale.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './UserDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    Locale,
    UserDropdown,
    BBadge,
    BCard,
    BCardText,
    BPopover,
    // Navbar Components
    DarkToggler,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
